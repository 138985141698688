/* eslint-disable no-unreachable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, EditableTable } from '@timeedit/ui-components';
import Tag from '@timeedit/ui-components/lib/src/components/Tag/Tag';
import _, { chain, difference } from 'lodash';
import { toCenterOfWeek, toDurationDisplay } from '../../utils';
import { Button, Checkbox, notification, Pagination, Popconfirm, Segmented, Tooltip, Typography } from 'antd';
import intl, { getInlineString } from 'i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  activitiesDeletingSelector,
  activitiesLoadingSelector,
  activitiesPaginationSelector,
  activitiesSelector,
  activitiesSendingSelector,
  allActivitySeriesIdsSelector,
  changeActivityGroupBy,
  changeSelectedActivityIds,
  changeTablePagination,
  deleteActivities,
  publishActivities,
  selectedActivitiesSelector,
  TActivityGroupBy,
  activityGroupBySelector,
  fetchActivitiesChildren,
  changeExpandedRows,
} from 'activities/pages/slices/activity.slice';
import { useActivitiesWatcher } from 'activities/pages/hooks/useActivitiesWatcher';
import { ActivityEvents, TActivityRowData, TActivityStartEndDate } from 'activities/pages/types/activity.type';
import { ActivityElementValue } from '@timeedit/types/lib/types';
import './ActivitiesTable.scss';
import ActivityValueCell from './ActivityValueCell';
import TEObjectManager from '../../services/TEObjects.service';
import TEActivitiesManager, {
  generateFormInstances,
  triggerDownloadActivities,
} from 'activities/services/activities.service';
import { organizationSelector } from 'slices/organization.slice';
import { useSendToReviewPopover, withFormInstanceStatus } from './ActivityTable.hooks';
import { startAndEndToWeeks, toActivityReviewStatus, weekRangeChar } from './ActivitiesTable.utils';
import PreferencesIcon from '@timeedit/ui-components/lib/src/assets/icons/new/Preferences.svg';
import CoreIcon from '@timeedit/ui-components/lib/src/assets/icons/new/Core.svg';
import { TableRowSelection } from 'antd/es/table/interface';
import { useAppFeatureFlags } from 'activities/hooks/useAppFeatureFlags';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { ColumnTitle } from '@timeedit/ui-components/lib/src/components/EditableTable/ColumnTitle';
import { TActivityValue } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityValue.type';
import { CaretDownFilled, CaretRightFilled, DownloadOutlined } from '@ant-design/icons';

const language = intl.messages;

export default function ActivitiesTable() {
  const dispatch = useDispatch();
  const activities = useSelector(activitiesSelector);
  const allActivitySeriesIds = useSelector(allActivitySeriesIdsSelector);
  const pagination = useSelector(activitiesPaginationSelector);
  const deleting = useSelector(activitiesDeletingSelector);
  const sending = useSelector(activitiesSendingSelector);
  const loading = useSelector(activitiesLoadingSelector);
  const selectedRowKeys = useSelector(selectedActivitiesSelector);
  const organization = useSelector(organizationSelector);
  const activityGroupBy = useSelector(activityGroupBySelector);

  const [teObjectsLoading, setTeObjectsLoading] = useState<undefined | boolean>(undefined);
  const [sendingToReview, setSendingToReview] = useState(false);

  // HOOKS
  useActivitiesWatcher();
  const {
    open: prefPopoverOpen,
    setOpen: setPrefPopoverOpen,
    title: prefPopoverTitle,
  } = useSendToReviewPopover({
    selectedRowKeys,
    activitySeries: activities,
  });
  const { formInstanceStatus } = withFormInstanceStatus({ activities });

  const expandIcon = ({
    expanded,
    onExpand,
    record,
  }: {
    expanded: boolean;
    onExpand: (record: any, event: React.MouseEvent<HTMLElement>) => void;
    record: any;
  }) => {
    if (!record?.children) return <span />;
    return expanded ? (
      <span
        className={'expand-icon'}
        onClick={(e) => {
          e.stopPropagation();
          return onExpand(record, e);
        }}
      >
        <CaretDownFilled />
      </span>
    ) : (
      <span
        className={'expand-icon'}
        onClick={(e) => {
          e.stopPropagation();
          return onExpand(record, e);
        }}
      >
        <CaretRightFilled />
      </span>
    );
  };

  const onPaginationChange = useCallback(
    (page?: number, perPage?: number) => {
      dispatch(
        changeTablePagination({
          page,
          perPage,
        }),
      );
    },
    [dispatch],
  );

  // Make sure that the user doesn't stay on a page that doesn't exist anymore when the number of activities changes
  useEffect(() => {
    const lastPage = Math.ceil(pagination.totalActivities / pagination.perPage);

    if (pagination.page > lastPage) {
      onPaginationChange(lastPage, pagination.perPage);
    }
  }, [pagination, onPaginationChange]);

  useEffect(() => {
    if (organization?.id) TEActivitiesManager.init({ organizationId: organization.id });
  }, [organization?.id]);

  const activitiesRelated = useMemo(() => {
    const objectTypes: string[] = [];
    const fields: string[] = [];
    const objects: string[] = [];

    const handleActivityValue = (activityValue: TActivityValue) => {
      // fields
      if (activityValue?.type === 'field') {
        fields.push(activityValue?.extId);
      }
      // object-filters
      else if (activityValue?.submissionValueType === 'FILTER') {
        objectTypes.push(activityValue?.extId);
      }
      // te-objects
      else if (activityValue?.type === 'object') {
        objectTypes.push(activityValue?.extId);
        objects.push(...(activityValue?.value ?? []));
      }
      return activityValue?.extId;
    };

    const columns = _.uniq(
      activities.flatMap((activity) => {
        // groupBy: "ACTIVITY_SERIES"
        if ('activityIds' in activity) {
          objects.push(activity.primaryObject);
          objects.push(activity.activityType);
          return activity.allValues.map((item) => {
            return handleActivityValue(
              item.activityValue as TActivityValue /* TODO: Find out why this is necessary. Seems strange! Probably version mismatch between packages */,
            );
          });
        }

        // groupBy: "FLAT"
        objects.push(activity.metadata.primaryObject);
        objects.push(activity.metadata.activityType);
        return activity.values.map(handleActivityValue);
      }),
    );

    return {
      columns,
      objectTypes: chain(objectTypes).compact().uniq().value(),
      objects: chain(objects).compact().uniq().value(),
      fields: chain(fields).compact().uniq().value(),
    };
  }, [activities]);

  useEffect(() => {
    const doGettingObjectsAndTypes = async () => {
      try {
        setTeObjectsLoading(true);
        const response = await Promise.allSettled([
          TEObjectManager.getObjectTypes(activitiesRelated.objectTypes),
          TEObjectManager.getFields(activitiesRelated.fields),
          TEObjectManager.getObjects(activitiesRelated.objects),
        ]);
        if (response[0].status === 'rejected') {
          notification.error({
            message: language.errors as string,
            description: getInlineString('failed_to_get', 'object types'),
          });
        }

        if (response[1].status === 'rejected') {
          notification.error({
            message: language.errors as string,
            description: getInlineString('failed_to_get', 'fields'),
          });
        }

        if (response[2].status === 'rejected') {
          notification.error({
            message: language.errors as string,
            description: getInlineString('failed_to_get', 'objects'),
          });
        }
      } finally {
        setTeObjectsLoading(false);
      }
    };
    doGettingObjectsAndTypes();
  }, [activitiesRelated.objectTypes, activitiesRelated.objects, activitiesRelated.fields]);

  const { sendToReview, showDmStatusFilter, examFlowV3 } = useAppFeatureFlags();

  const formatActivities = (activities: TActivityRowData[], activitySeriesId: string) => {
    return activities.map((act): TActivityRowData => {
      const indexedValue: Record<string, ActivityElementValue> = _.keyBy(act.values, 'extId');
      return {
        activitySeriesId,
        id: act._id,
        formInstanceId: act.formInstanceId,
        activityType: act.metadata.activityType,
        numberOfTracks: act.track,
        primaryObject: act.metadata.primaryObject,
        activityStatuses: [act.activityStatus],
        activityReviewStatus: toActivityReviewStatus(formInstanceStatus[act.formInstanceId]),
        ...activitiesRelated.columns.reduce((results, extId) => {
          return {
            ...results,
            [extId]: indexedValue[extId]?.value,
          };
        }, {}),
        weeks: startAndEndToWeeks(act.metadata),
        duration: toDurationDisplay(act.metadata.length),
        activityId: act._id,
      };
    });
  };

  const formattedActivities: TActivityRowData[] = useMemo(() => {
    return activities.map((act): TActivityRowData => {
      // = group by activity series
      if ('activityIds' in act) {
        const indexedValue: Record<string, ActivityElementValue> = act.allValues.reduce(
          (results, { activityValue }) => {
            if (!activityValue) return results;
            return {
              ...results,
              [activityValue.extId]: activityValue.value,
            };
          },
          {},
        );

        return {
          ..._.pick(act, [
            'primaryObject',
            'activityType',
            'activitySeriesId',
            'numberOfTracks',
            'formInstanceId',
            'activityStatuses',
            'activityIds',
          ]),
          id: act.activitySeriesId,
          weeks: act.startEndDates
            .map((date: TActivityStartEndDate) => {
              return startAndEndToWeeks(date);
            })
            .join(', '),
          duration: act.durations.map((duration: number) => toDurationDisplay(duration)).join(', '),
          ...activitiesRelated.columns.reduce((results, extId) => {
            return {
              ...results,
              [extId]: indexedValue[extId],
            };
          }, {}),
          activityReviewStatus: toActivityReviewStatus(formInstanceStatus[act.formInstanceId]),
          children:
            act.children && act.children.length
              ? formatActivities(act.children as unknown as TActivityRowData[], act.activitySeriesId)
              : [],
          activityId: undefined,
        };
      }

      // = group by "FLAT"
      const indexedValue: Record<string, ActivityElementValue> = _.keyBy(act.values, 'extId');
      return {
        activitySeriesId: act.activitySeriesGroup?.activitySeriesId ?? '', // ? TODO: Check if this is correct
        id: act._id, // ? TODO: Check if this is correct
        activityId: act._id,
        formInstanceId: act.formInstanceId,
        activityType: act.metadata.activityType,
        numberOfTracks: act.metadata.totalTracks,
        primaryObject: act.metadata.primaryObject,
        activityStatuses: [act.activityStatus],
        activityReviewStatus: toActivityReviewStatus(formInstanceStatus[act.formInstanceId]),
        ...activitiesRelated.columns.reduce((results, extId) => {
          return {
            ...results,
            [extId]: indexedValue[extId]?.value,
          };
        }, {}),
        weeks: startAndEndToWeeks(act.metadata),
        duration: act.metadata.length,
      };
    });
  }, [activities, activitiesRelated, formInstanceStatus]);

  const selectedItemsCount = useMemo(() => {
    return selectedRowKeys?.length ?? 0;
  }, [selectedRowKeys]);

  const activityActionsDisabled = useMemo(() => {
    return !formattedActivities.filter(
      (item) =>
        (selectedRowKeys?.includes(item.activitySeriesId) || selectedRowKeys?.includes(item.activityId)) &&
        // Selected rowKey can be an activityId for group by "FLAT"
        (showDmStatusFilter
          ? !item.activityStatuses.includes(EActivityStatus.IN_REVIEW)
          : !item.activityReviewStatus.disabled),
    ).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys, formattedActivities]);

  const selectedActivityContainsWeekRangeActivity = useMemo(() => {
    return formattedActivities.some(
      (item) =>
        (selectedRowKeys?.includes(item.activitySeriesId) || selectedRowKeys?.includes(item.activityId)) &&
        item.weeks.includes(weekRangeChar),
    );
  }, [selectedRowKeys, formattedActivities]);

  const columns = useMemo(
    (): Column[] => [
      {
        title: (
          <ColumnTitle
            title={
              formattedActivities.length
                ? TEObjectManager.getObjectTypeLabelByObject(formattedActivities[0].primaryObject)
                : (language['activities.overview.table.primary_object'] as string)
            }
          />
        ),
        width: 320,
        key: 'primaryObject',
        render: (rowData: TActivityRowData) => {
          return <div data-testid="ACTIVITY_ROW">{TEObjectManager.getObjectLabel(rowData.primaryObject)}</div>;
        },
        resizable: true,
      },
      {
        title: <ColumnTitle title={language.status as string} />,
        width: 120,
        key: 'status',
        render: (rowData) =>
          showDmStatusFilter ? (
            rowData.activityStatuses.map((status: string) => <Tag key={null}>{status.replace(/_/g, ' ')}</Tag>)
          ) : (
            <Tag icon={rowData.activityReviewStatus?.icon} color={rowData.activityReviewStatus?.color}>
              {rowData.activityReviewStatus?.label}
            </Tag>
          ),
      },
      {
        title: <ColumnTitle title={language['activities.overview.table.activity_type'] as string} />,
        width: 220,
        key: 'activityType',
        render: (rowData: TActivityRowData) => {
          return <div>{TEObjectManager.getObjectLabel(rowData.activityType)}</div>;
        },
        resizable: true,
      },
      {
        title: <ColumnTitle title={language['activities.overview.table.tracks'] as string} />,
        width: 120,
        key: 'numberOfTracks',
        dataIndex: 'numberOfTracks',
      },
      {
        title: <ColumnTitle title={language['activities.overview.table.weeks'] as string} />,
        width: 250,
        key: 'weeks',
        render: (rowData: TActivityRowData) => {
          return rowData.weeks ? `w ${rowData.weeks}` : `N/A`;
        },
      },
      {
        title: <ColumnTitle title={language['activities.overview.table.duration'] as string} />,
        width: 120,
        key: 'duration',
        dataIndex: 'duration',
      },
      ...(teObjectsLoading === false
        ? activitiesRelated.columns.map((col: string) => ({
            title: <ColumnTitle title={TEObjectManager.getObjectTypeLabel(col, TEObjectManager.getFieldLabel(col))} />,
            width: 250,
            key: `activity_${col}`,
            resizable: true,
            render: (rowData: TActivityRowData) => {
              return <ActivityValueCell activityValue={rowData[col]} />;
            },
          }))
        : []),
    ],
    [teObjectsLoading, activitiesRelated, Object.values(formInstanceStatus)],
  );

  const onSendToAM = () => {
    dispatch(publishActivities(selectedRowKeys));
  };

  const onDelete = () => {
    dispatch(deleteActivities(selectedRowKeys));
  };
  const onSendToReview = async () => {
    if (!selectedRowKeys) return;
    setSendingToReview(true);
    await generateFormInstances(organization.id, selectedRowKeys);

    dispatch(changeSelectedActivityIds([]));
    setSendingToReview(false);
  };
  const onExportActivities = async () => {
    if (!selectedRowKeys) return;
    await triggerDownloadActivities(organization.id, selectedRowKeys, activityGroupBy);
  };

  const allChildKeys = useMemo(() => {
    return [...formattedActivities.filter((a) => a.children && a.children.length).map((a) => a.children)]
      .flat()
      .map((children) => children?.activityId);
  }, [formattedActivities]);

  const handleRowSelection: TableRowSelection<unknown>['onChange'] = (selectedKeys) => {
    const allKeysOnPage: string[] = [
      ...formattedActivities.map(({ activityId, activitySeriesId }) =>
        activityGroupBy === 'ACTIVITY_SERIES' ? activitySeriesId : activityId,
      ),
      ...(allChildKeys as string[]),
    ];
    const allOtherPageKeys = difference(selectedRowKeys, allKeysOnPage);
    const removedKeys = difference(allKeysOnPage, selectedKeys as string[]);
    const addedKeys = difference(selectedKeys as string[], allKeysOnPage as string[]);

    const updatedSelectedKeys = [...allOtherPageKeys, ...(selectedKeys as string[]), ...addedKeys].filter(
      (key) => !removedKeys.includes(key),
    );

    dispatch(changeSelectedActivityIds(updatedSelectedKeys));
  };

  const handleSelectAll = (checked: boolean) => {
    dispatch(changeSelectedActivityIds(checked ? [...allActivitySeriesIds, ...(allChildKeys as string[])] : []));
  };

  const onActivityGroupByChange = (val: TActivityGroupBy) => {
    dispatch(changeActivityGroupBy(val));
    handleSelectAll(false); // Deselect all when swapping group by because row key is different anyway
  };

  const groupByOptions: { label: string; value: TActivityGroupBy }[] = [
    { label: language['activites.activity_series'] as string, value: 'ACTIVITY_SERIES' },
    { label: language['activites.all_activities'] as string, value: 'FLAT' },
  ];

  return (
    <div className="activity-series-table te-flex te-flex-col te-flex-1" data-testid="ACTIVITIES_TABLE">
      {examFlowV3 && (
        <div className="ant-row te-flex" style={{ marginBottom: '10px' }}>
          <div className="ant-col" style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
            {language['general.group_by'] as string}
          </div>
          <div className="ant-col">
            <Segmented<TActivityGroupBy>
              options={groupByOptions}
              onChange={(value) => {
                onActivityGroupByChange(value);
              }}
            />
          </div>
        </div>
      )}

      <div className="header">
        <Typography.Text>
          {getInlineString(
            activityGroupBy === 'ACTIVITY_SERIES'
              ? 'activities.overview.table.showing_number_of_activities'
              : 'activities.overview.table.showing_number_of_activities_flat',
            activities.length,
            pagination.totalActivities,
          )}
        </Typography.Text>
      </div>

      <EditableTable
        virtual
        loading={loading || teObjectsLoading === true}
        dataSource={formattedActivities}
        rowKey={(record) => record.activityId || record.activitySeriesId}
        columns={columns}
        size="small"
        tableLayout="fixed"
        onChange={(pagination) => {
          onPaginationChange(pagination.current, pagination.pageSize);
        }}
        onRow={(row) => ({
          onClick: () => {
            if (!examFlowV3) return;
            if (row.activityId) {
              document.dispatchEvent(
                new CustomEvent(ActivityEvents.OPEN_ACTIVITY_DRAWER, {
                  detail: {
                    activityId: row.activityId,
                  },
                }),
              );
            } else {
              document.dispatchEvent(
                new CustomEvent(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, {
                  detail: {
                    activitySeriesId: row.activitySeriesId,
                  },
                }),
              );
            }
          },
        })}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys,
          onChange: handleRowSelection,
          columnWidth: 32,
          columnTitle: (
            <Checkbox
              checked={selectedItemsCount === allActivitySeriesIds.length + allChildKeys.length}
              indeterminate={selectedItemsCount > 0 && selectedItemsCount < allActivitySeriesIds.length}
              onChange={(event) => handleSelectAll(event.target.checked)}
            />
          ),
        }}
        pagination={false}
        expandable={{
          showExpandColumn: examFlowV3,
          expandIcon,
          onExpand: (expanded, record) => {
            dispatch(changeExpandedRows({ rowId: record.id, expanded }));
            if (examFlowV3 && expanded && !record.children?.length) {
              dispatch(fetchActivitiesChildren(record.activitySeriesId));
            }
          },
        }}
        footer={() => (
          <>
            {activityGroupBy === 'ACTIVITY_SERIES'
              ? getInlineString('activities.overview.table.number_of_activity_series_selected', selectedItemsCount)
              : getInlineString('activities.overview.table.number_of_activites_selected', selectedItemsCount)}
            <Popconfirm
              disabled={!selectedItemsCount}
              title={prefPopoverTitle}
              onConfirm={onSendToReview}
              okText={
                <div className="te-flex te-items-center">
                  <img alt="te-prefs" src={PreferencesIcon} width={16} height={16} />
                  <span className="te-ml-2 te-mr-2">Send</span>
                </div>
              }
              okButtonProps={{
                style: { verticalAlign: 'middle', display: 'inline-flex', alignItems: 'center' },
              }}
              cancelButtonProps={{ style: { verticalAlign: 'middle' } }}
              open={prefPopoverOpen}
              onOpenChange={setPrefPopoverOpen}
              icon={null}
            >
              {sendToReview ? (
                <Tooltip
                  title={
                    selectedActivityContainsWeekRangeActivity
                      ? (language['activities.overview.table.send_to_pref_not_for_ranges'] as string)
                      : ''
                  }
                >
                  <Button
                    loading={sendingToReview}
                    disabled={
                      !selectedItemsCount || activityActionsDisabled || selectedActivityContainsWeekRangeActivity
                    }
                    size="small"
                    className="te-ml-2 te-flex te-items-center"
                    data-testid="REVIEW_BUTTON"
                  >
                    <img alt="te-prefs" src={PreferencesIcon} width={16} height={16} />
                    &nbsp;
                    {language['activities.overview.table.send_to_review_button'] as string}
                  </Button>
                </Tooltip>
              ) : null}
            </Popconfirm>
            <Popconfirm
              disabled={!selectedRowKeys?.length}
              onConfirm={onSendToAM}
              title={getInlineString('activities.overview.table.publish_confirmation', selectedItemsCount)}
              okText={language['activities.overview.table.publish_confirm_button_in_modal'] as string}
              okButtonProps={{
                icon: <img alt="te-core" src={CoreIcon} width={16} height={16} style={{ verticalAlign: 'sub' }} />,
              }}
              cancelText={language.cancel as string}
              icon={null}
            >
              <Tooltip
                title={
                  activityGroupBy === 'FLAT'
                    ? (language['activities.overview.table.action_disabled_in_flat_view'] as string)
                    : ''
                }
              >
                <Button
                  disabled={!selectedItemsCount || activityActionsDisabled || activityGroupBy === 'FLAT'}
                  size="small"
                  className="te-ml-2 te-flex te-items-center"
                  loading={sending}
                  data-testid="SEND_BUTTON"
                >
                  <img alt="te-core" src={CoreIcon} width={16} height={16} />
                  &nbsp;
                  {language['activities.overview.table.publish_confirm_button'] as string}
                </Button>
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              disabled={!selectedItemsCount}
              title={getInlineString('activities.overview.table.delete_confirmation', selectedItemsCount)}
              onConfirm={onDelete}
            >
              <Tooltip
                title={
                  activityGroupBy === 'FLAT'
                    ? (language['activities.overview.table.action_disabled_in_flat_view'] as string)
                    : ''
                }
              >
                <Button
                  loading={deleting}
                  disabled={!selectedItemsCount || activityActionsDisabled || activityGroupBy === 'FLAT'}
                  size="small"
                  className="te-ml-2"
                  data-testid="DELETE_BUTTON"
                >
                  {language.delete as string}
                </Button>
              </Tooltip>
            </Popconfirm>

            {examFlowV3 && (
              <Popconfirm
                title={getInlineString('general.export.items', selectedItemsCount)}
                onConfirm={onExportActivities}
                okText={language['general.export.trigger_export'] as string}
                okButtonProps={{
                  icon: <DownloadOutlined />,
                }}
                icon={null}
              >
                <Button size="small" className="te-ml-2" data-testid="EXPORT_BUTTON" disabled={!selectedItemsCount}>
                  {language['general.export.title'] as string}
                </Button>
              </Popconfirm>
            )}

            <Pagination
              current={pagination.page}
              pageSize={pagination.perPage}
              total={pagination.totalActivities}
              onChange={onPaginationChange}
              size="small"
            />
          </>
        )}
      />
    </div>
  );
}
